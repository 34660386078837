<template>
  <div class="home-wrap">
    <div class="header">
      <img src="@/assets/imgs/形状@2x.png" alt />所有信息仅用于身份验证，我们不会以任何形式收集
    </div>
    <div class="content">
      <div class="title">选择认证方式</div>
      <ul class="authentication-list" ref="authList">
        <li v-if="telephone" class="authentication-item" @click="goUrl('/telephone')">
          <img src="@/assets/imgs/icon_api@2x.png" alt />
          <div class="mid">
            <div class="name">手机号认证</div>
            <div class="des">要求确保该手机号是由本人身份证办理</div>
          </div>
          <div class="right">
            <van-icon name="arrow" :color="listCount === 3 ? '#fff' : null" />
          </div>
        </li>
        <!-- <li
          class="authentication-item"
          @click="goUrl('/authenticationConfirm')"
        >
          <img src="@/assets/imgs/icon_real@2x.png" alt />
          <div class="mid">
            <div class="name">人脸识别认证</div>
            <div class="des">需用手机录制一段朗读数字的认证视频</div>
          </div>
          <div class="right">
            <van-icon name="arrow" color="#fff" />
          </div>
        </li> -->
        <li class="authentication-item" @click="handleGetH5Face">
          <img src="@/assets/imgs/icon_real@2x.png" alt />
          <div class="mid">
            <div class="name">人脸识别认证</div>
            <div class="des">需用手机录制一段朗读数字的认证视频</div>
          </div>
          <div class="right">
            <van-loading v-if="loading" type="spinner" size="16px" />
            <van-icon v-else name="arrow" :color="listCount === 2 ? '#fff' : null" />
          </div>
        </li>
        <li class="authentication-item" @click="goUrl('/bankCard')">
          <img src="@/assets/imgs/icon_api@2x(1).png" alt />
          <div class="mid">
            <div class="name">银行卡认证</div>
            <div class="des">需校验本人的银行卡信息及预留手机号</div>
          </div>
          <div class="right">
            <van-icon name="arrow" />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import creditApis from '@/api/creadit'

export default {
  data() {
    return {
      telephone: '',
      listCount: 0,
      loading: false,
    }
  },
  created() {
    this.telephone = this.$store.state.creditMsg.telephone
  },
  computed: {
    creditMsg() {
      return this.$store.state.creditMsg
    },
    redirectUrl() {
      return `${window.location.origin}/faceverifying`
    },
  },
  mounted() {
    this.getAuthList()
  },
  methods: {
    goUrl(url) {
      const { redirectUrl } = this.$route.query

      this.$router.push({
        path: url,
        query: {
          redirectUrl
        }
      })
    },
    async handleGetH5Face() {
      try {
        if (this.loading) return
        this.loading = true
        const { userFullName, userIdCardNo, userId } = this.creditMsg
        const model = {
          from: 'browser',
          idNo: userIdCardNo,
          name: userFullName,
          userIdE: userId,
          url: `${this.redirectUrl}?userIdE=${userId}`,
        }
        const res = await creditApis.h5FaceLogin(model)
        if (res) {
          const a = document.createElement('a')
          a.href = res
          a.click()
          window.open(res)
        } else if (res.status !== 200) {
          this.$toast({
            message: res.msg,
            duration: 2000,
          })
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },

    // hack 方式
    // 之前的list 不是配置生成的，需要获取当前的list，判断哪一项是第一个
    // 如果是 3 项，则说明手机号是第一个
    // 如果是 2 项，则说明人脸是第一个
    // 获取认证方式 list
    getAuthList() {
      const { authList } = this.$refs
      const list = authList.querySelectorAll('li')
      this.listCount = list.length
    },
  },
}
</script>
<style lang="less" scoped>
.home-wrap {
  .header {
    padding: 0 16px;
    display: flex;
    align-items: center;

    img {
      width: 11px;
      height: 14px;
      margin-right: 8px;
    }

    width: 375px;
    height: 40px;
    background: rgba(230, 247, 242, 1);
    font-size: 12px;
    font-family: PingFangSC-Regular,
    PingFang SC;
    font-weight: 400;
    color: rgba(22, 187, 131, 1);
    line-height: 40px;
  }

  .content {
    padding: 16px;

    .title {
      width: 90px;
      height: 24px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(60, 64, 67, 1);
      line-height: 24px;
      margin-bottom: 16px;
    }

    .authentication-list {
      .authentication-item {
        display: flex;
        align-items: center;
        width: 343px;
        height: 80px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 5px 20px 0px rgba(197, 202, 213, 0.25);
        border-radius: 8px;
        margin-bottom: 16px;
        padding: 0 16px;
        cursor: pointer;

        img {
          width: 48px;
          height: 48px;
          margin-right: 16px;
        }

        .mid {
          flex: 1;

          .name {
            height: 24px;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: rgba(17, 26, 52, 1);
            line-height: 24px;
            margin-bottom: 4px;
          }

          .des {
            height: 20px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(109, 119, 144, 1);
            line-height: 20px;
          }
        }

        .right {
          width: 14px;
          height: 14px;
          font-size: 14px;
          color: #111a34;
        }
      }

      .authentication-item:first-child {
        background: linear-gradient(90deg,
            rgba(22, 118, 255, 1) 0%,
            rgba(93, 160, 252, 1) 100%);
        box-shadow: 0px 5px 20px 0px rgba(79, 139, 243, 0.3);

        .mid {
          .name {
            color: rgba(255, 255, 255, 1);
          }

          .des {
            color: white;
          }
        }
      }
    }
  }
}
</style>
